var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"mb-10"},[_c('v-container',{staticClass:"general-content",attrs:{"fluid":""}},[_c('v-row',{staticClass:"description-row my-2 mx-5"},[_c('v-col',{attrs:{"cols":"12","sm":"7","md":"8"}},[_c('v-row',{staticClass:"ma-0 v-flex align-center"},[_c('v-col',{staticClass:"pa-0 mr-3",attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"title-icon"},[_vm._v("palette")])],1),_c('v-col',{staticClass:"pa-0 mr-3",attrs:{"cols":"auto"}},[_c('h1',{staticClass:"my-0"},[_vm._v(_vm._s(_vm.$t('admin_appea.title')))])]),_c('v-col',{staticClass:"pa-0 mr-3",attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"my-0"},'v-icon',attrs,false),on),[_vm._v("info")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('admin_appea.description')))])])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"px-4 px-sm-0 pb-6 mt-6 general-content",staticStyle:{"background":"var(--v-background-2-base)","border":"1px solid var(--v-background-5-base)"},attrs:{"max-height":"","tile":"","elevation":"0"}},[_c('v-card-actions',{staticClass:"py-2 px-0 px-sm-4 justify-end flex-wrap",staticStyle:{"gap":"16px 8px"}},[_c('v-btn',{staticStyle:{"border-color":"var(--v-primary-base)"},attrs:{"tile":"","outlined":"","height":"32","title":_vm.$t('admin_appea.undoBtn'),"disabled":!_vm.dirty || _vm.saving},on:{"click":_vm.update}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("undo")]),_vm._v(" "+_vm._s(_vm.$t('admin_appea.discardBtn'))+" ")],1),_c('v-btn',{attrs:{"height":"32","depressed":"","tile":"","color":"primary","dark":!_vm.dirty ? false : true,"disabled":!_vm.dirty,"loading":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('admin_appea.saveBtn'))+" "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("save")])],1)],1),_c('v-divider'),_c('v-card',{staticClass:"mx-auto form-fields",attrs:{"elevation":"0","color":"transparent","max-width":"588"}},[_c('div',{staticClass:"background title-form pa-2 pl-4"},[_vm._v(" "+_vm._s(_vm.$t('admin_appea.colors'))+" ")]),_c('div',{staticClass:"form-fields pt-6"},[_c('Fields',{attrs:{"id":"appearance-fields","schema":{
                  accentColor: {
                    type: 'string',
                    fieldType: 'color',
                    label: _vm.$t('admin_appea.schema.primary'),
                    optional: true,
                    sizeSmall: 12,
                    sizeMedium: 12,
                    sizeLarge: 12,
                    newDesign: true
                  },
                  sideBarColor: {
                    type: 'string',
                    fieldType: 'color',
                    label: _vm.$t('admin_appea.schema.backSide'),
                    optional: true,
                    sizeSmall: 12,
                    sizeMedium: 12,
                    sizeLarge: 12,
                    newDesign: true
                  },
                  sideBarTextColor: {
                    type: 'string',
                    fieldType: 'color',
                    label: _vm.$t('admin_appea.schema.textSide'),
                    optional: true,
                    sizeSmall: 12,
                    sizeMedium: 12,
                    sizeLarge: 12,
                    newDesign: true
                  },
                  backgroundColor: {
                    type: 'string',
                    fieldType: 'color',
                    label: _vm.$t('admin_appea.schema.backView'),
                    optional: true,
                    sizeSmall: 12,
                    sizeMedium: 12,
                    sizeLarge: 12,
                    newDesign: true
                  },
                  allowDarkMode: {
                    type: 'boolean',
                    fieldOptions: {
                      disabled: _vm.desktopApp,
                      inset: true
                    },
                    label: _vm.$t('admin_appea.schema.darkM'),
                    sizeSmall: 12,
                    optional: true,
                    newDesign: true
                  },
                  allowLightweightLayout: {
                    type: 'boolean',
                    fieldOptions: {
                      inset: true
                    },
                    label: _vm.$t('admin_appea.schema.miniLayout'),
                    sizeSmall: 12,
                    optional: true,
                    newDesign: true
                  },
                  authBackgroundImage: {
                    type: 'file',
                    fieldType: 'file',
                    fieldOptions: {
                      fileType: 'image'
                    },
                    label: _vm.$t('admin_appea.schema.backPho'),
                    optional: true,
                    sizeSmall: 12,
                    sizeMedium: 12,
                    sizeLarge: 12,
                    newDesign: true
                  },
                  logo: {
                    type: 'file',
                    fieldType: 'file',
                    fieldOptions: {
                      fileType: 'image'
                    },
                    label: _vm.$t('admin_appea.schema.logo'),
                    optional: true,
                    sizeSmall: 12,
                    sizeMedium: 12,
                    sizeLarge: 12,
                    newDesign: true
                  },
                  loginLogo: {
                    type: 'file',
                    fieldType: 'file',
                    fieldOptions: {
                      fileType: 'image'
                    },
                    label: _vm.$t('admin_appea.schema.login'),
                    optional: true,
                    sizeSmall: 12,
                    sizeMedium: 12,
                    sizeLarge: 12,
                    newDesign: true
                  },
                  icon: {
                    type: 'file',
                    fieldType: 'file',
                    fieldOptions: {
                      fileType: 'image'
                    },
                    label: _vm.$t('admin_appea.schema.icon'),
                    optional: true,
                    sizeSmall: 12,
                    sizeMedium: 12,
                    sizeLarge: 12,
                    newDesign: true
                  }
                },"disabled":_vm.$apollo.loading || _vm.saving,"validationErrors":{}},model:{value:(_vm.environment),callback:function ($$v) {_vm.environment=$$v},expression:"environment"}}),_c('div',{staticClass:"background title-form mt-4 pa-2 pl-4"},[_vm._v(" "+_vm._s(_vm.$t('admin_appea.customConfigs'))+" ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('Fields',{attrs:{"name":"useCustomCss","schema":{
                    useCustomCss: {
                      type: 'boolean',
                      fieldOptions: {
                        disabled: _vm.desktopApp,
                        inset: true
                      },
                      label: _vm.$t('admin_appea.schema.useCustomCss'),
                      sizeSmall: 12,
                      optional: true,
                      newDesign: true
                    }
                  }},model:{value:(_vm.environment),callback:function ($$v) {_vm.environment=$$v},expression:"environment"}}),_c('v-slide-y-transition',[(_vm.environment.useCustomCss)?_c('Fields',{attrs:{"schema":{
                      customCss: {
                        type: 'string',
                        fieldType: 'code',
                        fieldOptions: {
                          mode: 'text/css'
                        },
                        label: _vm.$t('admin_appea.schema.customCss'),
                        optional: true,
                        sizeSmall: 12
                      }
                    },"disabled":_vm.$apollo.loading || _vm.saving,"validationErrors":{}},model:{value:(_vm.environment),callback:function ($$v) {_vm.environment=$$v},expression:"environment"}}):_vm._e()],1),(!_vm.desktopApp)?_c('Fields',{attrs:{"name":"customizeWelcomeEmail","schema":{
                    customizeWelcomeEmail: {
                      type: 'boolean',
                      fieldOptions: {
                        disabled: _vm.desktopApp,
                        inset: true
                      },
                      label: _vm.$t('admin_appea.customWelco'),
                      sizeSmall: 12,
                      optional: true,
                      newDesign: true
                    }
                  }},model:{value:(_vm.environment),callback:function ($$v) {_vm.environment=$$v},expression:"environment"}}):_vm._e(),(_vm.environment.customizeWelcomeEmail)?_c('Fields',{attrs:{"schema":{
                    welcomeEmailSubject: {
                      type: 'string',
                      label: _vm.$t('admin_appea.schema.emailSub.label'),
                      fieldOptions: {
                        placeholder: _vm.$t(
                          'admin_appea.schema.emailSub.placeholder'
                        )
                      },
                      optional: true,
                      sizeSmall: 12,
                      newDesign: true
                    },
                    welcomeEmailBody: {
                      type: 'string',
                      fieldType: 'html',
                      label: _vm.$t('admin_appea.schema.emailBod.label'),
                      description: _vm.$t(
                        'admin_appea.schema.emailBod.description'
                      ),
                      optional: true
                    }
                  },"disabled":_vm.$apollo.loading || _vm.saving,"validationErrors":{}},model:{value:(_vm.environment),callback:function ($$v) {_vm.environment=$$v},expression:"environment"}}):_vm._e(),(!_vm.desktopApp)?_c('Fields',{attrs:{"name":"customizeResetPasswordEmail","schema":{
                    customizeResetPasswordEmail: {
                      type: 'boolean',
                      fieldOptions: {
                        disabled: _vm.desktopApp,
                        inset: true
                      },
                      label: _vm.$t('admin_appea.customWelco'),
                      sizeSmall: 12,
                      optional: true,
                      newDesign: true
                    }
                  }},model:{value:(_vm.environment),callback:function ($$v) {_vm.environment=$$v},expression:"environment"}}):_vm._e(),(_vm.environment.customizeResetPasswordEmail)?_c('Fields',{attrs:{"schema":{
                    resetPasswordEmailSubject: {
                      type: 'string',
                      label: _vm.$t('admin_appea.schema.resetSub.label'),
                      fieldOptions: {
                        placeholder: _vm.$t(
                          'admin_appea.schema.resetSub.placeholder'
                        )
                      },
                      optional: true,
                      sizeSmall: 12,
                      newDesign: true
                    },
                    resetPasswordEmailBody: {
                      type: 'string',
                      fieldType: 'html',
                      label: _vm.$t('admin_appea.schema.resetBod.label'),
                      description: _vm.$t(
                        'admin_appea.schema.resetBod.description'
                      ),
                      optional: true
                    }
                  },"disabled":_vm.$apollo.loading || _vm.saving,"validationErrors":{}},model:{value:(_vm.environment),callback:function ($$v) {_vm.environment=$$v},expression:"environment"}}):_vm._e()],1)],1)])],1)],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"bottom":"","timeout":5000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" "),_c('v-btn',{attrs:{"color":_vm.snackbarColor ? '' : 'primary',"text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" "+_vm._s(_vm.$t('admin_appea.okBtn'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }