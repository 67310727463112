



































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Environment } from '@/models'
import {
  EnvironmentCreateFragment,
  EnvironmentAppearanceUpdateFragment
} from './fragments'
import Loading from '@/components/Loading.vue'
import Fields from '@/components/form/Fields.vue'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import gql from 'graphql-tag'
import cleanData from '@/utils/gql/cleanData'
import getEnv from '@/plugins/getEnv'
@Component({
  components: {
    Loading,
    Fields
  },
  apollo: {
    savedEnvironment: {
      query: gql`
        query ($environmentId: ID) {
          savedEnvironment: environment(environmentId: $environmentId) {
            ...EnvironmentCreate
            ...EnvironmentAppearanceUpdate
          }
        }
        ${EnvironmentCreateFragment}
        ${EnvironmentAppearanceUpdateFragment}
      `,
      variables() {
        return {
          environmentId: this.environmentId
        }
      }
    }
  }
})
export default class AdminSettingsAppearance extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string
  desktopApp = !!getEnv('VUE_APP_IS_DESKTOP')

  saving = false

  snackbar = false
  snackbarText = ''
  snackbarColor = ''

  savedEnvironment: Readonly<Environment> | null = null
  environment?: Partial<Environment> = {}

  @Watch('savedEnvironment')
  update(newData: Environment) {
    this.$set(this, 'environment', _cloneDeep(this.savedEnvironment))
  }

  get dirty() {
    return !_isEqual(this.environment, this.savedEnvironment)
  }

  async save() {
    if (!this.environment || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($environmentId: ID, $custom: EnvironmentCustomInput) {
            setEnvironmentCustom(
              environmentId: $environmentId
              custom: $custom
            ) {
              ...EnvironmentCreate
              ...EnvironmentAppearanceUpdate
            }
          }
          ${EnvironmentCreateFragment}
          ${EnvironmentAppearanceUpdateFragment}
        `,
        // Parameters
        variables: {
          environmentId: this.environment._id,
          custom: cleanData(
            this.environment,
            EnvironmentAppearanceUpdateFragment
          )
        }
      })
      this.savedEnvironment = result.data.setEnvironmentCustom
      this.snackbar = true
      this.snackbarText = this.$t('admin_appea.script.save.text').toString()
    } catch (e) {
      this.$emit('error', e)
      this.snackbar = true
      this.snackbarText = 'Error: ' + e.message
      this.snackbarColor = 'error'
      console.error(e)
    } finally {
      this.saving = false
    }
  }
}
